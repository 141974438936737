import { getToken } from '@/helpers/localStorage.helper';
import { DateTime } from 'luxon';

export const isAuthenticatedGuard = (to, from, next) => {
  const exp = localStorage.getItem('exp');
  const now = DateTime.now();
  const tokenExp = DateTime.fromSeconds(parseInt(exp));
  if (getToken() && tokenExp > now) {
    next();
  } else {
    localStorage.clear();
    next({ name: 'login' });
  }
};

export const isPublic = (to, from, next) => {
  const exp = localStorage.getItem('exp');
  const now = DateTime.now();
  const tokenExp = DateTime.fromSeconds(parseInt(exp));
  const darkMode = localStorage.getItem('dark-mode');
  if (getToken() && tokenExp > now) {
    next({ name: 'inicio' });
  } else {
    localStorage.clear();
    localStorage.setItem('dark-mode', darkMode);
    next();
  }
};
