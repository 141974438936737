import { createStore } from 'vuex';

export default createStore({
  state: {
    isLoading: true,
  },

  mutations: {
  },

  actions: {
  },
  
  getters: {
  },

  modules: {},
});
