import base64 from 'base-64';
import utf8 from 'utf8';

export const encode = (var1) => {
  try {
    const bytes = utf8.encode(var1);
    return base64.encode(bytes);
  } catch (error) {
    return null;
  }
};
export const decode = (var1) => {
  try {
    const bytes = base64.decode(var1);
    return utf8.decode(bytes);
  } catch (error) {
    return null;
  }
};
