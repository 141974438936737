import { encode } from './encode.helper';

export const setToken = (token) => {
  localStorage.setItem('token', token);
};

export const getToken = () => {
  return localStorage.getItem('token') || '';
};

export const setObjectToLocalStorage = (name, value) => {
  const val = JSON.stringify(value);
  localStorage.setItem(`${name}`, val);
};

export const getObjectToLocalStorage = (name) => {
  return JSON.parse(localStorage.getItem(`${name}`)) || null;
};

export const setEncodeObjectToStorage = (name, value) => {
  const val = encode(JSON.stringify(value));
  localStorage.setItem(`${name}`, val);
};
export const getEncodeObjectToStorage = () => {};
