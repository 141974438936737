import { createRouter, createWebHistory } from 'vue-router';
import { isAuthenticatedGuard, isPublic } from './router/auth-guard';

const routes = [
  {
    path: '',
    name: 'defaultLayout',
    component: () => import(/* webpackChunkName: "DefaultLayout" */ './layouts/DefaultLayout.vue'),
    children: [
      {
        path: '',
        redirect: { name: 'login' },
      },
      {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "Login" */ './pages/Login.vue'),
        beforeEnter: [isPublic],
      },
    ],
  },
  {
    path: '/',
    name: '',
    beforeEnter: [isAuthenticatedGuard],
    component: () => import(/* webpackChunkName: "MainLayout" */ './App.vue'),
    children: [
      {
        path: '',
        redirect: { name: 'inicio' },
      },
      {
        path: 'inicio',
        name: 'inicio',
        beforeEnter: [isAuthenticatedGuard],
        component: () => import('./pages/Inicio.vue'),
      },
      {
        path: 'calendario',
        name: 'calendario',
        beforeEnter: [isAuthenticatedGuard],
        component: () => import('./pages/Calendario.vue'),
      },
      {
        path: 'programacion',
        name: 'programacion',
        beforeEnter: [isAuthenticatedGuard],
        component: () => import('./pages/students/Programacion.vue'),
      },
      {
        path: 'horario',
        name: 'horario',
        beforeEnter: [isAuthenticatedGuard],
        component: () => import('./pages/students/Horario.vue'),
      },
      {
        path: 'calificacion',
        name: 'calificacion',
        beforeEnter: [isAuthenticatedGuard],
        component: () => import('./pages/students/Calificacion.vue'),
      },
      {
        path: 'kardex',
        name: 'kardex',
        beforeEnter: [isAuthenticatedGuard],
        component: () => import('./pages/students/Kardex.vue'),
      },
      {
        path: 'libreta',
        name: 'libreta',
        beforeEnter: [isAuthenticatedGuard],
        component: () => import('./pages/students/Libreta.vue'),
      },
      {
        path: 'recursos',
        name: 'recursos',
        beforeEnter: [isAuthenticatedGuard],
        component: () => import('./pages/students/Recursos.vue'),
      },
      {
        path: 'saeta',
        name: 'saeta',
        beforeEnter: [isAuthenticatedGuard],
        component: () => import('./pages/students/Saeta.vue'),
      },

      {
        path: 'horariodoc',
        name: 'horariodoc',
        beforeEnter: [isAuthenticatedGuard],
        component: () => import('./pages/teachers/HorarioDoc.vue'),
      },
      {
        path: 'listadoc',
        name: 'listadoc',
        beforeEnter: [isAuthenticatedGuard],
        component: () => import('./pages/teachers/ListaDoc.vue'),
      },
      {
        path: 'recursosdoc',
        name: 'recursosdoc',
        beforeEnter: [isAuthenticatedGuard],
        component: () => import('./pages/teachers/RecursosDoc.vue'),
      },
      {
        path: 'siscalificacion',
        name: 'siscalificacion',
        beforeEnter: [isAuthenticatedGuard],
        component: () => import('./pages/teachers/Calificacion.vue'),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: {
      name: 'login',
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
});

export default router;
